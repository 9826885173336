import { useContext, useEffect, useState } from "react";
import { IconButton, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { deleteUser } from "../../firebase/functions/Functions";
import { MasterPromptContext } from "../../contexts/MasterPrompt";
import { MasterToastContext } from "../../contexts/MasterToast";


export default function UserListItem({authUser,user,openProfileDialog}) {
  const {prompt} = useContext(MasterPromptContext);
  const {toast} = useContext(MasterToastContext)

  const title = `${user.username}${user.group !== "Agent" ? ` (${user.group})` : ""}`;
  const lastLogin =user.lastLogin === "2000-01-01 12:00:00" ? "never" : user.lastLogin;
  const confirmDeleteUser = async () => {
    const response = await prompt.open({
      title:"Delete User", 
      content: `Please confirm that you wish to delete ${user.username}`
    });
    if(response !== "Yes") return prompt.close();
    try{
      toast.info(`${user.username} is being deleted...`)
      const res = await deleteUser({authUser,user});      
      console.log(res);
      toast.success(`${user.username} has been deleted.`)
     }
     catch(f){
       toast.error((`An error occured while deleting ${user.username}.`))
       console.error(f)
     }
     prompt.close();
  }  

  return (                    
  <ListItem
  secondaryAction={
    <IconButton 
    onClick={confirmDeleteUser}
    edge="end" 
    aria-label="delete">
      <DeleteIcon />
    </IconButton>
  }
  >
    <ListItemIcon>
                <IconButton
                  edge="start"
                  onClick={() => openProfileDialog(user)}
                  aria-label="edit"
                >
                  <EditIcon />
                </IconButton>
    </ListItemIcon>
      <ListItemText
      primary={title}
      secondary={
        <>
          <Typography
            variant="body2"
            color="text.primary"
          >
            {`${user.firstName} ${user.lastName}`}
          </Typography>
          {`Last Login: ${lastLogin}`}
        </>
      }
      />
    </ListItem>)

}
