export const makeDaySchObj = (events) => {
    return events
    .flatMap(({start,end, val},i) =>{
      return [
        {
          $base: "Sequence",
          time: {$base:"Time", value:start},
          value: {$base: "Choice", enumerated: {$base: "Enumerated", value:val}}
        },
        end && {
          $base: "Sequence",
          time: {$base:"Time", value:end},
          value: {$base: "Choice", "null": {$base: "Null"}}
        }
        ].filter(item => item);
    })
    .reduce((acc,item,i) => {
      acc["day-schedule"][i+1] = item
      return acc; 
    },{$base: "Sequence", "day-schedule": {$base:"List"}})
  }