import React, { createContext, useState, useEffect } from "react";
import { auth } from "../firebase/auth/Auth";
import { doc, onSnapshot } from "firebase/firestore";
import { db, syncUser } from "../firebase/firestore/Firestore";

export const AuthContext = createContext({
  authUser: null,
  user: null,
  authMessage: "",
});

export default function AuthProvider({ children }) {
  const [authUser, setAuthUser] = useState(null);
  const [user, setUser] = useState(null);
  const [authMessage, setAuthMessage] = useState("");

  useEffect(() => {
    return auth.onAuthStateChanged(async (user) => {
      if (!user && !authUser) return;
      if (!user && authUser) return setAuthUser(null);
      if (!authUser) return setAuthUser(user);
    }, []);
  });

  useEffect(() => {
    if (!authUser) return;
    syncUser({ user: authUser })
        .then((r) => r.text())
        .then(console.log);
  }, [authUser]);

  useEffect(() => {
    if (!authUser && user) return setUser(null);
    if (authUser && !user){
      onSnapshot(doc(db, "users", authUser.uid), (doc) => setUser({uid:doc.id,...doc.data()}));
    }
  }, [authUser, user]);

  useEffect(() => {
    console.log("user data", user);
  }, [user]);

  return (
    <AuthContext.Provider value={{ authUser, authMessage, user }}>
      {children}
    </AuthContext.Provider>
  );
}
