import React, { useEffect, useContext, useState } from "react";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { authenticate, getAuthToken, signInEntelicloudUser, signOutUser } from '../../firebase/auth/Auth';
import { AuthContext } from "../../contexts/Auth";
import { useNavigate, useNavigationType } from "react-router-dom";
import { MasterWaitScreenContext } from "../../contexts/MasterWaitScreen";
import { MasterToastContext } from "../../contexts/MasterToast";
import { ConstructionOutlined } from "@mui/icons-material";


const theme = createTheme();

export default function SignIn() {
  const {user,authUser} = useContext(AuthContext);
  const {waitScreen} = useContext(MasterWaitScreenContext);
  const { toast } = useContext(MasterToastContext);

  const [entelicloudData, setEntelicloudData] = useState();


  const navigate = useNavigate();
  const navigateType = useNavigationType();
  
  const parentUrl = (window.location != window.parent.location)
  ? document.referrer || window.location.ancestorOrigins.item(0)
  : document.location.href;

  const authenticateUser = async ({data, isEntelicloud}) =>{
    waitScreen.open('having a little chat with enteliCLOUD...');
    const token = await (isEntelicloud ? getAuthToken(data) : authenticate(data))
    waitScreen.text('It was a nice chat. Signing in now...');
    const signInRes = await signInEntelicloudUser(token);
    waitScreen.text('Sign in was successful.  Just have to fetch your user data...');
    return signInRes;
  }



  const handler = async ({data,origin}) => {
    console.log(`MCS received message: ${data.msg}`,data);
      if(!/\.entelicloud\.com\/?$/.test(parentUrl)) return;
      if(data.msg === "CREDS"){
        if(data.err){
          console.log(`MCS detected error: ${data.err}`)
          console.log(`MCS posting message: REQUEST CREDS`)
          return window.parent.postMessage({msg: "REQUEST CREDS"},"*");
        } 
        console.log(`MCS is setting entelicloudData`)
        setEntelicloudData({...data,origin});        
      }
    }


  useEffect(() => {
    console.log(`MCS adding listener`)
    window.addEventListener("message", handler);
    return () => window.removeEventListener("message", handler);
  }, [])

  useEffect(() =>{
    if(!entelicloudData){
      console.log(`MCS requsting creds`)
      window.parent.postMessage({msg: "REQUEST CREDS"},"*");
    }
  },[entelicloudData])

  useEffect(() =>{
    const func = async () => {
      if(!entelicloudData || authUser) return;
        try{
          const isEntelicloud = /\.entelicloud\.com$/.test(entelicloudData.origin);
          console.log(`MCS is logging in`)
          return await authenticateUser({data:entelicloudData,isEntelicloud});
        } catch(f){
          console.error(f);
          setEntelicloudData(null)
          waitScreen.text('There was a problem.');
        }
    }
    func();
  },[authUser, entelicloudData])

  useEffect(() =>{
    const func = async () => {
    if(!entelicloudData || !user) return;
    if(user?.username !== entelicloudData?.user){
      console.log(`MCS is logging out`, {user,entelicloudData},user?.username,entelicloudData?.user)
      return signOutUser();
    }
    waitScreen.close();
    console.log(`MCS is navigating away`)
    navigate(navigateType === "POP" ? "/" : -1);
  }
  func();
  },[user, entelicloudData])

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const [username,password] = [formData.get('username'),formData.get('password')];
    if(!username) return;
    if(!password) return;
    const data = {username, password}
    try{
     await authenticateUser({data})
    } catch(f){
      console.error(f);
      toast.error("An error occured.");
      waitScreen.close();
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          {!/\.entelicloud\.com?\/$/.test(parentUrl) ? (
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="username"
              label="Username"
              name="username"
              autoComplete="username"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>

          </Box>) : null}
        </Box>
      </Container>
    </ThemeProvider>
  );
}