import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';


export default function Toast({
    open, 
    severity,
    autoHideDuration = 6000,
    handleClose =  () => null,
    clickAwayClose = false,
    children
}) {

   const onClose = (event, reason) => {
     if (!clickAwayClose && reason === 'clickaway') return;
     handleClose(event, reason)
   };

  return (
      <Snackbar open={open} autoHideDuration={autoHideDuration} onClose={onClose}>
        <Alert onClose={onClose} severity = {severity}>
          {children}
        </Alert>
      </Snackbar>
  )
}
