import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { FormControl, InputLabel } from '@mui/material';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};



export default function MultipleSelect({
    labelId,
    title,
    handleChange = () => null, 
    values = [],
    items = []}) {
    const renderValue = (selected) => selected
    .map(val => items.find(({value}) => value === val)?.label)
    .join(', ')
  return (
    <FormControl sx={{width:250}}>
        <InputLabel id={labelId}>{title}</InputLabel>
        <Select
          labelId={labelId}
          multiple
          value={values}
          onChange={handleChange}
          input={<OutlinedInput label={title} />}
          renderValue={renderValue}
          MenuProps={MenuProps}
        >
          {items.map(({label, value}, i) => (
            <MenuItem key={`${value}_${i}`} value={value}>
              <Checkbox checked={values.findIndex(val => val === value) > -1} />
              <ListItemText primary={label} />
            </MenuItem>
          ))}
        </Select>
        </FormControl>
  );
}
