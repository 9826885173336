import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./components/app/App";
import reportWebVitals from "./reportWebVitals";
import AuthProvider from "./contexts/Auth";
import { BrowserRouter } from "react-router-dom";
import MasterToastProvider from "./contexts/MasterToast";
import MasterWaitScreenProvider from "./contexts/MasterWaitScreen";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import ObjectsProvider from "./contexts/Objects";
import UsersProvider from "./contexts/Users";
import MasterPromptProvider from "./contexts/MasterPrompt";

ReactDOM.render(
  <React.StrictMode>
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <MasterWaitScreenProvider>
        <MasterToastProvider>
          <MasterPromptProvider>
            <AuthProvider>
              <UsersProvider>
                <ObjectsProvider>
                  <BrowserRouter>
                    <App />
                  </BrowserRouter>
                </ObjectsProvider>
              </UsersProvider>
            </AuthProvider>
          </MasterPromptProvider>
        </MasterToastProvider>
      </MasterWaitScreenProvider>
    </LocalizationProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
