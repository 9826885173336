import { Stack, Typography } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

export default function WaitScreen({open, handleClose, text}) {

  return (
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.snackbar + 1 }}
        open={open}
        onClick={handleClose}
      >
        <Stack alignItems="center" spacing={2}>
        <CircularProgress  color="inherit" />
        <Typography variant='caption'>{text}</Typography>
        </Stack>
      </Backdrop>
  );
}
