import { useState } from "react";
import { List, ListItemButton, ListItemText } from "@mui/material";
import PhoneIcon from "@mui/icons-material/Phone";
import MessageIcon from "@mui/icons-material/Message";
import EmailIcon from "@mui/icons-material/Email";
import AlarmDialog from "./AlarmDialog";

export default function AlarmList({ authUser, user, alarms = [] }) {
  const [alarmDialogOpen, setAlarmDialogOpen] = useState(false);
  const [data, setData] = useState({});



  const openAlarmDialog = (data) => {
    setData(data);
    setAlarmDialogOpen(true);
  };

  return (
    <List>
      {alarms
        .sort(({ displayName: a }, { displayName: b }) => (a > b ? 1 : -1))
        .map(
          ({
            key,
            displayName,
            notFound,
            description,
            object,
            site,
            device,
            prefs: { email = false, text = false, voice = false } = {},
          }) => (
            <ListItemButton
              onClick={openAlarmDialog.bind(null, {
                key,
                displayName,
                notFound,
                description,
                object,
                site,
                device,
                prefs: {email,text,voice},
              })}
            >
              <ListItemText
                sx={{ color: `text.${notFound ? "disabled" : "primary"}` }}
              >
                {displayName || "Unknown Alarm"}
              </ListItemText>
              {email && <EmailIcon />}
              {text && <MessageIcon />}
              {voice && <PhoneIcon />}
            </ListItemButton>
          )
        )}
      <AlarmDialog
        {...{
          onCloseAlarmDialog: () => setAlarmDialogOpen(false),
          alarmDialogOpen,
          authUser,
          user,
          data,
        }}
      />
    </List>
  );
}
