import { format, set as setDate, startOfToday } from "date-fns";

const { isNil } = require("lodash");

export const convertWeeklySchedule = (weeklySchedule) => {
  return Object.entries(weeklySchedule).reduce(
    (acc, [dayKey, { ["day-schedule"]: daySchedule }]) => {
      const day = dayKey % 7;
      Object.values(daySchedule).forEach(
        ({
          time: { value: time },
          value: {
            value: { enumerated: { value: val } = {} },
          },
        }) =>
          isNil(val)
            ? (acc[day].at(-1).end = time)
            : acc[day].push({ val, start: time })
      );
      return acc;
    },
    [[], [], [], [], [], [], []]
  );
};

const formatTime = (time) => {
  const [h, m] = time.slice(0, 5).split(":");
  return `${+h % 12 || 12}:${m} ${+h < 12 ? "AM" : "PM"}`;
};

export const formatTimes = (start, end) =>
  `${formatTime(start)}${end ? ` - ${formatTime(end)}` : "- 12:00 AM"}`;

const getTimeStr = (date) => format(date, "HH:mm:00.00");

export const getEvent = ({ start, end, val }) =>
  end && (end.getHours() || end.getMinutes())
    ? { start: getTimeStr(start), end: getTimeStr(end), val }
    : { start: getTimeStr(start), val };

export const getStartTime = ({
  weeklySchedule,
  currentWeekDay,
  eventIndex,
}) => {
  if (isNil(eventIndex)) return null;
  const time = weeklySchedule?.[currentWeekDay]?.[eventIndex]?.start;
  if (!time) return null;
  const [hours, minutes] = time.slice(0, 5).split(":").map(Number);
  return setDate(new Date(), { hours, minutes });
};

export const getEndTime = ({ weeklySchedule, currentWeekDay, eventIndex }) => {
  if (isNil(eventIndex)) return null;
  const time = weeklySchedule?.[currentWeekDay]?.[eventIndex]?.end;
  if (!time) return startOfToday();
  const [hours, minutes] = time.slice(0, 5).split(":").map(Number);
  return setDate(new Date(), { hours, minutes });
};

const getMultiScheduleConflict = ({weeklySchedule, weekDay, start, end}) => {
  return weekDay.find(day => getScheduleConflict({weeklySchedule, weekDay : day, start, end}))
}

export const getScheduleConflict = ({weeklySchedule, weekDay, eventIndex, start, end}) => {
  if(Array.isArray(weekDay)) return getMultiScheduleConflict({weeklySchedule, weekDay, start, end});
  const [startStr, endStr] = [start,end].map(getTimeStr)
  if(start < end) return weeklySchedule[weekDay].find((event,i) =>
  i !== eventIndex && endStr >= event.start && (!event.end || startStr <= event.end));
  return weeklySchedule[weekDay].find((event,i) =>
  i !== eventIndex && (!event.end || startStr <= event.end)) ||
  ((end.getHours() || end.getMinutes()) && weeklySchedule[(weekDay + 1) % 7].find(event =>
  endStr >= event.start))
}
