import app from "../app/App";
import { getFirestore} from "firebase/firestore";

export const db = getFirestore(app)
//TODO
// move over to a functions file
export const syncUser = async ({user}) => {
    const idToken = await user.getIdToken(true);
    const url = 'https://app.mechanicalcontrolsystems.com/services/syncUserData';
    const headers = {'Authorization':`Bearer ${idToken}`};
    return fetch(url, {headers});
}

export const syncUsers = async ({authUser,users}) => {
    const idToken = await authUser.getIdToken(true);
    const url = 'https://app.mechanicalcontrolsystems.com/services/syncUsersData';
    const method = 'POST';
    const body = JSON.stringify({users});
    const headers = {
        'Authorization':`Bearer ${idToken}`,
        'Content-Type':'application/json'
    };
    return fetch(url, {headers, method, body});
}

export const updateAlarmPrefs = async ({authUser, user: {uid},alarmId,prefs}) => {
    const idToken = await authUser.getIdToken(true);
   const url = 'https://app.mechanicalcontrolsystems.com/services/updateAlarmPrefs';
   const method = 'PATCH';
   const body = JSON.stringify({uid, prefs, alarmId});
    const headers = {
        'Authorization':`Bearer ${idToken}`,
        'Content-Type':'application/json'
    };
    return fetch(url, {headers, method, body});
}




export const updateUser = async ({authUser, prop, value, user:{username,client}}) => {
    const idToken = await authUser.getIdToken(true);
   const url = 'https://app.mechanicalcontrolsystems.com/services/updateUser';
   const method = 'PUT';
   const body = JSON.stringify({prop, value, username, client});
    const headers = {
        'Authorization':`Bearer ${idToken}`,
        'Content-Type':'application/json'
    };
    return fetch(url, {headers, method, body});
}