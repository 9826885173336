import { createContext, useState } from "react";

export const MasterWaitScreenContext = createContext({
  open: false,
  setOpen: () => null,
  text: "",
  setText: () => null,
  handleClose: () => null,
  setHandleClose: () => null,
  //clickAwayClose: false,
  //setClickAwayClose: () => null,
});

export default function MasterWaitScreenProvider({ children }) {
  const [open, setOpen] = useState(false);
  const [handleClose, setHandleCloseInternal] = useState(() => () => null);
  const [text, setText] = useState("")
  //const [clickAwayClose, setClickAwayClose] = useState(false);
  const waitScreen = {
    open: (str = "") => (setOpen(true), setText(str)),
    close: () => (setOpen(false), setText("")),
    text: str => setText(str)
  }
  const setHandleClose = func => setHandleCloseInternal(() => func)

  return (
    <MasterWaitScreenContext.Provider
      value={{
        waitScreen,
        open,
        setOpen,
        handleClose,
        setHandleClose,
        text,
        setText
      }}
    >
      {children}
    </MasterWaitScreenContext.Provider>
  );
}
