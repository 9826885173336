import app from "../app/App";
import { getAuth, signInWithCustomToken, signOut } from "firebase/auth";


export const auth = getAuth(app);

export const signInEntelicloudUser = async token => {
    const cred = await signInWithCustomToken(auth, token)
    const user = cred.user
    return user;
}

export const authenticate = async ({username,password}) => {
    const creds = btoa(`${username}:${password}`);
    const url = 'https://app.mechanicalcontrolsystems.com/services/authenticate'
    // TODO
    // replace client var with lookup of subdomain
    // make get and only have Authorization header with Basic encodetoken
    const headers = {'Content-Type':'application/json'};
    const client = 'greentree';
    const body = JSON.stringify({client,creds});
    const params = {method:'POST', headers, body};
    const res = await fetch(url, params);
    console.log('authenticate response', res)
    return await res.text();
}

export const getAuthToken = async (data) => {
    const url = 'https://app.mechanicalcontrolsystems.com/services/getAuthToken';
    const method = "POST";
    const headers = {'Content-Type':'application/json'};
    const body = JSON.stringify(data);
    const res = await fetch(url, {method, headers, body});
    return await res.text()
}

export const signOutUser = () => signOut(auth);
