import { useLocation } from "react-router-dom";
import Scheduler from "./Scheduler";


export default function SchedulerPage() {
  const {search} = useLocation();
  const params = new URLSearchParams(search);
  const [site,device,object] = [params.get('site'),params.get('device'),params.get('object')];
  return (
    <Scheduler site={site} device={device} object={object} />
  );
}
