import { Container, Typography } from "@mui/material";

export default function About() {
    return (
    <Container maxWidth="md">
    <Typography variant="h4" align="center">
      Mechanical Control Systems
    </Typography>
    <Typography variant="body1">
        We are the premiere global automation solution provider for the world.  
        If you see some awesome automation, it was probably us.
        All of our competitors suck and envy us.
        We know you want to thank us for the great honor of purchasing our services but no need.  
        We know you wake every day and go to sleep every night with the desire to express your 
        unworthy gratitude to MCS for all our supreme greatness.  Cheers.
    </Typography>
    </Container>
    )
    }