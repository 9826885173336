import { useContext, useEffect, useState } from "react";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {
  DialogActions,
  DialogContent,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { MasterToastContext } from "../../contexts/MasterToast";
import { MasterWaitScreenContext } from "../../contexts/MasterWaitScreen";
import {
  convertWeeklySchedule,
  getEndTime,
  getEvent,
  getScheduleConflict,
  getStartTime,
} from "./Helper";
import { cloneDeep, isEqual, isNil, unionWith } from "lodash";
import { getWeekDay, getWeekDays } from "../../utils/Utils";
import {
  updateWeeklyScheduleDay,
} from "../../firebase/functions/Functions";
import { startOfToday } from "date-fns";
import MultipleSelect from "../multiSelect/MultiSelect";
import { ObjectsContext } from "../../contexts/Objects";

export default function ScheduleDialog({
  authUser,
  site,
  device,
  object,
  currentWeekDay = 0,
  eventIndex, // if null then create
  weeklySchedule, // state objec from scheduler
  setWeeklySchedule, // reset state for scheduler after successful update
  openScheduleDialog,
  onCloseScheduleDialog,
}) {
  const { toast } = useContext(MasterToastContext);
  const { waitScreen } = useContext(MasterWaitScreenContext);
  const {getObject} = useContext(ObjectsContext)


  const [weekDay, setWeekDay] = useState(0);

  const [start, setStart] = useState();
  const [startError, setStartError] = useState(false);
  const [startHelperText, setStartHelperText] = useState("");

  const [end, setEnd] = useState();
  const [endError, setEndError] = useState(false);
  const [endHelperText, setEndHelperText] = useState("");

  const [submitDisabled, setSubmitDisabled] = useState(true);

  const title = `${isNil(eventIndex) ? "Add" : "Edit"} Weekly Event`;

  useEffect(() => {
    setStart(getStartTime({ weeklySchedule, currentWeekDay, eventIndex }));
    setEnd(getEndTime({ weeklySchedule, currentWeekDay, eventIndex }));
    setWeekDay(currentWeekDay);
  }, [weeklySchedule, currentWeekDay, eventIndex]);

  useEffect(() => {
    if(!start){
      setStartError(false);
      setStartHelperText('');
      return setSubmitDisabled(true);
    }
    if(start.toString() === "Invalid Date"){
      setStartHelperText('Invalid Date');
      return setSubmitDisabled(true);
    }
    setStartError(false);
    setStartHelperText('');
    if(!end){
      setEndError(false);
      setEndHelperText('');
      return setSubmitDisabled(true);
    }
    if(end.toString() === "Invalid Date"){
      setEndHelperText('Invalid Date');
      return setSubmitDisabled(true);
    }
    const conflict = getScheduleConflict(
      {weeklySchedule, weekDay, eventIndex, start, end}
      );
    if(conflict){
      setEndError(true);
      setEndHelperText('Schedule Conflict');
      return setSubmitDisabled(true);
    }
    setEndError(false);
    setEndHelperText(start >= end ? 'Ends Next Day' : '');
    setSubmitDisabled(false);
  }, [start, end, weekDay]);


  const handleWeekDayChange = ({ target: { value } }) => {
    console.log("weekday", value);
    setWeekDay(value);
  };

  const handleStartChange = (time) => {
    console.log("start time", time);
    setStart(time);
  };

  const handleEndChange = (time) => {
    console.log("end time", time);
    setEnd(time);
  };

  const deleteEvent = ({weekDay,eventIndex}) => {
    const events = cloneDeep(weeklySchedule[weekDay]);
    events.splice(eventIndex,1);
    return [{events, weekDay}];
  };

  const addEvents = ({weekDay, start,end}) => {
   return weekDay.reduce((acc,day) =>{
    const daySchedules = addEvent({weekDay: day, start,end})
    daySchedules.forEach(({weekDay,events}) =>{ 
    const ind = acc.findIndex(item => item.weekDay === weekDay);
    ~ind 
    ? acc[ind].events = unionWith(acc[ind].events, events, isEqual) 
    : acc.push({weekDay,events});
   })
   return acc;
   },[])


  }

  const addEvent = ({weekDay,eventIndex, start,end}) => {
    if(Array.isArray(weekDay)) return addEvents({weekDay, start,end});
    if(
      end <= start && 
      (end.getHours() || end.getMinutes())
      ) return [
        ...addEvent({weekDay,eventIndex, start,end: startOfToday()}),
        ...addEvent({weekDay: (weekDay + 1) % 7,eventIndex: null, start: startOfToday(),end}) 
      ];
    const events = cloneDeep(weeklySchedule[weekDay]);
    events[isNil(eventIndex) ? events.length : eventIndex] = getEvent({
      start,
      end,
      val: 0,
    });
    return [{events, weekDay}];
  };



  const handleSubmit = async (type) => {
    if(submitDisabled) return;
    waitScreen.open("Updating Schedule");
    try {
      // TODO
      // create getScheduleConflift function
      // takes weekschedule weekday and eventIndex
      // in eventIndex then ignore that that event when checking
      // if overnight then check following day
      // nut in use effect and every time start or end change check
      // if conflict disable submit
      // add error and helpertext in useEffect for start and end for invalid date and conflict
      // add helper text for next day
      /*
     */
      
      const changes = type === 'add' ? 
      addEvent({weekDay,eventIndex, start,end}) 
      : deleteEvent({weekDay,eventIndex});
      const updatePs = changes.map(({weekDay, events}) => updateWeeklyScheduleDay({
        authUser,
        site,
        device,
        scheduleIndex: object.split(",")[1],
        day: weekDay || 7,
        events: events.sort(({start: a},{start: b}) => a > b ? 1 : -1)
      }))
      const updateRes = await await Promise.all(updatePs);
      console.log(3, updateRes);
      waitScreen.text("Refreshing Display");
      const data = await getObject(`${site}/${device}/${object}`, true);
      console.log(4, data);
      setWeeklySchedule(convertWeeklySchedule(data["weekly-schedule"]));
      console.log(5);
      toast.success(`The schedule was updated.`);
      onCloseScheduleDialog();
    } catch (f) {
      toast.error("The schedule failed to update.");
      console.error(f);
    }
    waitScreen.close();
  };

  return (
    <Dialog
      open={openScheduleDialog}
      onClose={onCloseScheduleDialog}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Stack spacing={6} pt={1}>
          {isNil(eventIndex) ? (
            <MultipleSelect
            labelId="day-selector-label"
            title="Day(s)"
            items = {getWeekDays().map((day, i) => ({label:day.slice(0,3), value: i}))}
            values = {Array.isArray(weekDay) ? weekDay : [weekDay]}
            handleChange = {(({target:{value}}) => setWeekDay(value))}
            />
          ) : (
            <Typography variant="body1">{getWeekDay(weekDay)}</Typography>
          )}

          <TimePicker
            label="Start"
            value={start}
            onChange={handleStartChange}
            renderInput={(params) => <TextField
              {...params}
              sx={{maxWidth:250}}
              error = {startError}
              helperText = {startHelperText}
               />}
          />
          <TimePicker
            label="End"
            value={end}
            onChange={handleEndChange}
            renderInput={(params) => <TextField
              {...params}
              sx={{maxWidth:250}}
              error = {endError}
              helperText = {endHelperText}
               />}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="error"
          onClick={onCloseScheduleDialog}
        >
          Cancel
        </Button>
        {!isNil(eventIndex) ? (
          <Button
            variant="contained"
            color="warning"
            onClick={() => handleSubmit('delete')}
          >
            Delete
          </Button>
        ) : null}
        <Button
          variant="contained"
          disabled={submitDisabled}
          onClick={() => handleSubmit('add')}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}
