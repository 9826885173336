import React, { useContext, useEffect, useState } from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import MuiPhoneInput from "material-ui-phone-number";
import CloseIcon from "@mui/icons-material/Close";

import { MasterToastContext } from "../../contexts/MasterToast";
import { MasterWaitScreenContext } from "../../contexts/MasterWaitScreen";
import { isEmail, validE164 } from "../../utils/Utils";
import { addUser, getGroups } from "../../firebase/functions/Functions";

//abstract Profile so it can load other user's profiles

export default function UserCreatorDialog({ user, authUser, open, handleClose }) {
  const { toast } = useContext(MasterToastContext);
  const { waitScreen } = useContext(MasterWaitScreenContext);
  
  const [firstName, setFirstName] = useState("");
  const [firstNameError, setFirstNameError] = useState(false);
  const [firstNameHelperText, setFirstNameHelperText] = useState("");

  const [lastName, setLastName] = useState("");
  const [lastNameError, setLastNameError] = useState(false);
  const [lastNameHelperText, setLastNameHelperText] = useState("");

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [emailHelperText, setEmailHelperText] = useState("");

  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState(false);
  const [phoneNumberHelperText, setPhoneNumberHelperText] = useState("");

  const [groups, setGroups] = useState([]);

  const [group, setGroup] = useState("");
  const [groupError, setGroupError] = useState(false);
  const [groupHelperText, setGroupHelperText] = useState("");


  const [submitDisabled, setSubmitDisabled] = useState(true);
  
  const [closeButton, setCloseButton] = useState(true);
  
  const clickCloseButton = () => {
    setCloseButton(false)
  }


  useEffect(() => {
    console.log("closeButton",closeButton, open)
    if(closeButton) return;
    handleClose(false)
    setCloseButton(true)
  },[closeButton])

  const validateForm = () => {
    return firstName && lastName && email && group &&
    !firstNameError && !lastNameError && !emailError && !phoneNumberError
  }

  useEffect(() => {
    setSubmitDisabled(!validateForm());
  }, [firstName,lastName,email,phoneNumber,group]);

  useEffect(() => {
    if(open) return;
    setFirstName("");
    setLastName("");
    setEmail("");
    setPhoneNumber("");
    setGroup("")
    waitScreen.close();
  }, [open]);

  useEffect(() => {
    if(!open) return;
    (async () => {
      const groups = await getGroups({authUser});
      console.log(2323,groups)
      if(!groups.length) throw new Error ("No Groups");
      if(groups.length === 1) setGroup(groups[0]);
      setGroups(groups.length > 1 ? groups : [])
    })();
  }, [open]);

  const handleFirstNameChange = ({ target: { value } }) => {
    setFirstName(value);
    const error = value ? "" : "Cannot be blank";
    setFirstNameHelperText(error);
    setFirstNameError(!!error);
  }

  const handleLastNameChange = ({ target: { value } }) => {
    setLastName(value);
    const error = value ? "" : "Cannot be blank";
    setLastNameHelperText(error);
    setLastNameError(!!error);
  }

  const handleEmailChange = ({ target: { value } }) => {
    setEmail(value);
    const error = isEmail(value) ? "" : "Invalid Email";
    setEmailHelperText(error);
    setEmailError(!!error);
  }

  const handlePhoneNumberChange = (value) => {
    setPhoneNumber(value);
    const error = value.length > 2 || validE164(value) ? "" : "Invalid Phone Number";
    setPhoneNumberHelperText(error);
    setPhoneNumberError(!!error);
  }

  const handleGroupChange = ({target:{value}}) => {
    setGroup(value);
    const error = value ? "" : "Cannot be blank"; 
    setGroupHelperText(error);
    setGroupError(!!error);
  }

  const handleSubmit = async () => {
    setSubmitDisabled(true);
    waitScreen.open("Creating User...");

    try {
      console.log("user",{firstName,lastName,email,phoneNumber, group})
      const {userName} = await addUser({authUser, email,phoneNumber,firstName,lastName, group})
      console.log({userName})

      toast.success(`New User, ${userName}, has been created`)
      handleClose(false);
    } catch (f) {
      waitScreen.close();
      setSubmitDisabled(false);
      toast.error(f.toString());
      console.error(f);
    }
    
  };

  return (
    <Dialog open={open} onClose={() => handleClose(false)} fullWidth maxWidth="md">
      <DialogTitle>
      <Typography variant="h4">User Creator</Typography>
        <IconButton
          aria-label="close"
          onClick={clickCloseButton}
          sx={{
            position: 'absolute',
            right: 4,
            top: 4,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        </DialogTitle>
      <DialogContent dividers>
        <Stack>
          <TextField
            type="text"
            required
            variant="outlined"
            margin="normal"
            id="first-name-input"
            label="First Name"
            name="firstName"
            error={firstNameError}
            helperText={firstNameHelperText}
            autoFocus
            value={firstName}
            onChange={handleFirstNameChange}
          />
          <TextField
            type="text"
            required
            variant="outlined"
            margin="normal"
            id="last-name-input"
            label="Last Name"
            name="lastName"
            error={lastNameError}
            helperText={lastNameHelperText}
            value={lastName}
            onChange={handleLastNameChange}
          />
          <TextField
            type="email"
            required
            variant="outlined"
            margin="normal"
            id="email-input"
            label="Email"
            name="email"
            error={emailError}
            helperText={emailHelperText}
            value={email}
            onChange={handleEmailChange}
          />
          <MuiPhoneInput
            id="phone-number-input"
            defaultCountry={"us"}
            autoFormat={false}
            variant="outlined"
            margin="normal"
            error={phoneNumberError}
            helperText={phoneNumberHelperText}
            value={phoneNumber}
            onChange={handlePhoneNumberChange}
          />
          {groups.length > 1 ? (
          <FormControl  error = {groupError}>
        <InputLabel id="user-creator-dialog-group-select">Group</InputLabel>
        <Select
          labelId="user-creator-dialog-group-select-label"
          id="user-creator-dialog-group-select"
          value={group}
          label="Group"
          onChange={handleGroupChange}
        >
          <MenuItem value=""></MenuItem>
          {groups.map(g => (<MenuItem value={g}>{g}</MenuItem>))}
        </Select>
        <FormHelperText>{groupHelperText}</FormHelperText>
      </FormControl>
          ) : null}
        </Stack>

        <DialogActions>
          <Button
            variant="contained"
            disabled={submitDisabled}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}
