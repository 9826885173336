import { useContext, useEffect, useState } from "react";

import {
  Alert,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  IconButton,
  Stack,
  Switch,
  Typography,
} from "@mui/material";

import PhoneIcon from "@mui/icons-material/Phone";
import MessageIcon from "@mui/icons-material/Message";
import EmailIcon from "@mui/icons-material/Email";
import CloseIcon from "@mui/icons-material/Close";
import { updateAlarmPrefs } from "../../firebase/firestore/Firestore";
import { MasterToastContext } from "../../contexts/MasterToast";

export default function AlarmDialog({
  alarmDialogOpen,
  onCloseAlarmDialog,
  authUser,
  user,
  data: {
    key,
    displayName,
    notFound,
    description,
    object,
    site,
    device,
    prefs: { email = false, text = false, voice = false } = {},
  },
}) {
  const { toast } = useContext(MasterToastContext);
  
  const [prefs, setPrefs] = useState({})

  useEffect(() => {
    setPrefs({email,text,voice})
  },[email,text,voice,alarmDialogOpen])

  const handlePrefChange = async (pref) => {
    setPrefs((prefs) => ({...prefs, [pref]: !prefs[pref]}))
    try {
    const payload = {
      authUser,
      user,
      alarmId: key,
      prefs: {[pref]: !prefs[pref]},
    }
    document.body.style.cursor='wait'
    const res = await updateAlarmPrefs(payload);
      if (res.status !== 200) throw new Error(res.status);
      console.log("ALARM SETTING UPDATE SUCCESS",payload);
    } catch(f){
        console.error(f);
        setPrefs((prefs) => ({...prefs, [pref]: !prefs[pref]}));
        toast.error("Alarm Preferences Failed To Update");
    }
    document.body.style.cursor='default'
  }

  return (
    <Dialog
      open={alarmDialogOpen}
      onClose={onCloseAlarmDialog}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle sx={{ m: 0, px: 4 }}>
        <Typography variant="h5">{displayName}</Typography>
        <Typography variant="subtitle2">{site}</Typography>
        <IconButton
          aria-label="close"
          onClick={onCloseAlarmDialog}
          sx={{
            position: 'absolute',
            right: 4,
            top: 4,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        </DialogTitle>
      <DialogContent dividers>
        <Stack spacing={1}>
          {notFound ? (
            <Alert severity="warning">ALARM NOT CURRENTLY ACTIVE</Alert>
          ) : null}
          {description ? (
            <Typography variant="subtitle1">
              Description: {description}
            </Typography>
          ) : null}
          <FormGroup>
            <FormControlLabel
              control={<Switch 
                checked={prefs.email} 
                onChange={handlePrefChange.bind(null, 'email')}
                />}
              label={
                <>
                  <EmailIcon />
                  <Typography variant="overline">email</Typography>
                </>
              }
            />
            <FormControlLabel
              control={<Switch
                 checked={prefs.text}
                 onChange={handlePrefChange.bind(null, 'text')}
                 
                 />}
              label={
                <>
                  <MessageIcon />
                  <Typography variant="overline">text</Typography>
                </>
              }
            />
            <FormControlLabel
              control={<Switch 
                checked={prefs.voice} 
                onChange={handlePrefChange.bind(null, 'voice')}
                />}
              label={
                <>
                  <PhoneIcon />
                  <Typography variant="overline">voice</Typography>
                </>
              }
            />
          </FormGroup>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
