import { Fab } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import React, { useEffect, useState } from "react";
import UserCreatorDialog from "./UserCreatorDialog";


export default function UserCreatorButton({ user, authUser }) {
    const [openUserCreator, setOpenUserCreator] = useState(false);


    return (
        <Fab color="primary" aria-label="add"
        onClick={() => setOpenUserCreator(true)}
        >
        <AddIcon />
        <UserCreatorDialog
              authUser = {authUser}
              user= {user} 
              open={openUserCreator} 
              handleClose={setOpenUserCreator}
        />
        </Fab>
    )

}
