import { useContext } from "react";
import { AuthContext } from "../../contexts/Auth";
import { isBetters } from "../../utils/Utils";
import Users from "../users/Users";
import UserProfile from "../profile/UserProfile";

export default function UserPortal() {
  const { user } = useContext(AuthContext);
  return <>{isBetters(user?.group) ? <Users /> : <UserProfile />}</>;
}
