import React, { useContext, useEffect } from "react";
import { Routes, Route, useNavigate, useMatch } from "react-router-dom";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import CssBaseline from "@mui/material/CssBaseline";
import SignIn from "../signIn/SignIn";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import { AuthContext } from "../../contexts/Auth";
import Toast from "../toast/Toast";
import { MasterToastContext } from "../../contexts/MasterToast";
import MasterWaitScreen from "../../waitScreen/MasterWaitScreen";
import SchedulerPage from "../scheduler/SchedulerPage";
import About from "../about/About";
import Users from "../users/Users";
import UserProfile from "../profile/UserProfile";
import MasterPrompt from "../../prompt/MasterPrompt";
import UserPortal from "../userPortal/UserPortal";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://mechanicalcontrolsystems.com/">
        Mechanical Control Systems
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export default function App() {
  const { user } = useContext(AuthContext);
  const {
    open,
    severity,
    autoHideDuration,
    handleClose,
    text,
  } = useContext(MasterToastContext);
  const signInMatch = useMatch("/signIn");
  const aboutMatch = useMatch("/about");

  const navigate = useNavigate();
  useEffect(() => {
    if (window.location.host.includes("localhost")) return;
    if (!user && !signInMatch && !aboutMatch) navigate("/signIn");
  }, [user]);
  return (
    <>
      <div className="App">
        <CssBaseline />
        <>
          {/*<Header title="Mechanical Control Systems" user={user} />*/}
          <Routes>
          <Route path="/signIn" element={<SignIn />} />
          <Route path="/about" element={<About />} />
          <Route path="/profile" element={<UserProfile />} />
          <Route path="/users" element={<Users />} />
          <Route path="/userPortal" element={< UserPortal />} />
            <Route path="/scheduler" element={<SchedulerPage />} />
          </Routes>
        </>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
      <Toast 
      open = {open}
    severity = {severity}
    autoHideDuration = {autoHideDuration}
    handleClose = {handleClose}
    >{text}</Toast>
    <MasterWaitScreen></MasterWaitScreen>
    <MasterPrompt></MasterPrompt>
    </>
  );
}
