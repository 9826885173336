import { createContext, useState } from "react";

export const MasterToastContext = createContext({
  open: false,
  setOpen: () => null,
  severity: "success",
  setSeverity: () => null,
  autoHideDuration: 6000,
  setAutoHideDuration: () => null,
  handleClose: () => null,
  setHandleClose: () => null,
  clickAwayClose: false,
  setClickAwayClose: () => null,
  text: "",
  setText: () => null 
});

export default function MasterToastProvider({ children }) {
  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState("success");
  const [autoHideDuration, setAutoHideDuration] = useState(6000);
  const [handleClose, setHandleCloseInternal] = useState(() => () => null);
  const [clickAwayClose, setClickAwayClose] = useState(false);
  const [text, setText] = useState("");

  const setHandleClose = func => setHandleCloseInternal(() => func)
  const toastFunc = severity => (text) => {
        setSeverity(severity);
        setAutoHideDuration(6000);
        setHandleClose(() => setOpen(false));
        setClickAwayClose(false);
        setText(text);
        setOpen(true);
      }
  const toast = {
      success: toastFunc('success'),
      error: toastFunc('error'),
      info: toastFunc('info'),
      warning: toastFunc('warning'),
    }
  return (
    <MasterToastContext.Provider
      value={{
        open,
        setOpen,
        severity,
        setSeverity,
        autoHideDuration,
        setAutoHideDuration,
        handleClose,
        setHandleClose,
        clickAwayClose,
        setClickAwayClose,
        text,
        setText,
        toast
      }}
    >
      {children}
    </MasterToastContext.Provider>
  );
}
