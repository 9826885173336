import { useContext, useState } from "react";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { DialogActions, DialogContent, Stack } from "@mui/material";
import { MasterToastContext } from "../../contexts/MasterToast";
import { MasterWaitScreenContext } from "../../contexts/MasterWaitScreen";
import { changePassword } from "../../firebase/functions/Functions";

export default function PasswordChanger({
  authUser,
  open,
  onClose,
}) {
  const {toast} = useContext(MasterToastContext);
  const {waitScreen} = useContext(MasterWaitScreenContext);


  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [passwordHelperText, setPasswordHelperText] = useState("");

  const [reenterPassword, setReenterPassword] = useState("");
  const [reenterPasswordError, setReenterPasswordError] = useState(false);
  const [reenterPasswordHelperText, setReenterPasswordHelperText] = useState("");

  const [submitDisabled, setSubmitDisabled] = useState(true);
   
  const updatePasswordInputs = (error ="",reenterError = "") => {
    setPasswordHelperText(error);
    setPasswordError(!!error);
    setReenterPasswordHelperText(reenterError);
    setReenterPasswordError(!!reenterError)
    setSubmitDisabled(!reenterPassword || !!reenterError ||!!error);
  }

  const handlePasswordChange = ({ target: { value } }) => {
    setPassword(value);
    const reenterError = reenterPassword && value !== reenterPassword ? "No Match" : "";
    if(!value) return updatePasswordInputs("Value required.",reenterError);
    if(value.length < 8) return updatePasswordInputs(
      "Password must be at least 8 characters.",reenterError
      );
    if(!/[a-z]/.test(value)) return updatePasswordInputs(
      "Password must contain at least 1 lower case letter.",reenterError
      );
    if(!/[A-Z]/.test(value)) return updatePasswordInputs(
        "Password must contain at least 1 upper case letter.",reenterError
    );
    if(!/\d/.test(value)) return updatePasswordInputs(
      "Password must contain at least 1 number.",reenterError
    );
    return updatePasswordInputs("",reenterError);
  };

  const handleReenterPasswordChange = ({ target: { value } }) => {
    setReenterPassword(value);
    const  error = value && value !== password ? "No match" : "";
    setReenterPasswordHelperText(error);
    setReenterPasswordError(!!error)
    setSubmitDisabled(!value ||!!error);
  };

  const handleSubmit = async () => {
    setSubmitDisabled(true);
    waitScreen.open('Updating enteliCLOUD');
    try{
     await changePassword({authUser, password});
     toast.success(`Your password has been changed.`)
     onClose();
    }
    catch(f){
      setSubmitDisabled(false);
      toast.error('An error prevented your password from being changed.')
      console.error(f)
    }
    waitScreen.close();
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>Password</DialogTitle>
      <DialogContent>
        <Stack>
          <TextField
            type="password"
            required
            variant="outlined"
            margin="normal"
            id="new-password-input"
            label="Password"
            name="password"
            error = {passwordError}
            helperText = {passwordHelperText}
            autoFocus
            value={password}
            onChange={handlePasswordChange}
          />
          <TextField
            type="password"
            required
            variant="outlined"
            margin="normal"
            id="new-password-retype-input"
            label="Reenter Password"
            name="reenterPassword"
            error = {reenterPasswordError}
            helperText = {reenterPasswordHelperText}
            value={reenterPassword}
            onChange={handleReenterPasswordChange}
          />
        </Stack>

      </DialogContent>
      <DialogActions>
        <Button 
        variant="contained" 
        disabled={submitDisabled}
        onClick = {handleSubmit}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}
