// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyC1wKZW6z8QPmux5G54P0Ln24iiTDYhCaY",
  authDomain: "mcs-apps.firebaseapp.com",
  projectId: "mcs-apps",
  storageBucket: "mcs-apps.appspot.com",
  messagingSenderId: "1014650923257",
  appId: "1:1014650923257:web:88c5b443f08a50a39cda28",
  measurementId: "G-1CZDX3G37S"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
//export const analytics = getAnalytics(app);

export default app;