import { createContext, useState, useContext } from "react";
import { getObjectData } from "../firebase/functions/Functions";
import { AuthContext } from "./Auth";

export const ObjectsContext = createContext();


export default function ObjectsProvider({ children }) {
  const { authUser } = useContext(AuthContext);
 const [objects, setObjects] = useState({});


 const getObject = async (path,load) => {
     if(!load){
      const data = objects[path];
      if(data) return data;
     }
     const [site,device,object] = path.split("/")
     const data = await getObjectData({authUser,site,device,object});
     setObjects(objects => ({...objects,[path]: data}));
     return data;
}
return (
    <ObjectsContext.Provider value={{...objects, getObject}}>
      {children}
    </ObjectsContext.Provider>
  );
}
