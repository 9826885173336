import { makeDaySchObj } from "./Helpers";

export const addUser = async ({authUser,email,phoneNumber = "",firstName,lastName, client = "", group}) => {
    // group needs to be added later and then verified
    const idToken = await authUser.getIdToken(true);
   const url = 'https://app.mechanicalcontrolsystems.com/services/addUser';
   const method = 'POST';
   const body = JSON.stringify({email,phoneNumber,firstName,lastName, client, group});
    const headers = {
        'Authorization':`Bearer ${idToken}`,
        'Content-Type':'application/json'
    };
    const res = await fetch(url, {headers, method, body});
    return await res.json();
}

export const deleteUser = async ({authUser,user:{uid}}) => {
    // group needs to be added later and then verified
    const idToken = await authUser.getIdToken(true);
   const url = `https://app.mechanicalcontrolsystems.com/services/deleteUser?uid=${uid}`;
   const method = 'DELETE';
    const headers = {
        'Authorization':`Bearer ${idToken}`
    };
    const res = await fetch(url, {headers, method})
    return await res.text();
}

export const changePassword = async ({authUser,password}) => {
    // group needs to be added later and then verified
    const idToken = await authUser.getIdToken(true);
   const url = `https://app.mechanicalcontrolsystems.com/services/changePassword`;
   const method = 'PUT';
   const body = JSON.stringify({password});
    const headers = {
        'Authorization':`Bearer ${idToken}`,
        'Content-Type':'application/json'
    };
    const res = await fetch(url, {headers, method, body})
    return await res.text();
}

export const resetPassword = async ({authUser, user:{uid}}) => {
    const idToken = await authUser.getIdToken(true);
   const url = `https://app.mechanicalcontrolsystems.com/services/resetPassword`;
   const method = 'PUT';
   const body = JSON.stringify({uid});
    const headers = {
        'Authorization':`Bearer ${idToken}`,
        'Content-Type':'application/json'
    };
    const res = await fetch(url, {headers, method, body})
    return await res.text();
}

export const getObjectData = async ({authUser,site, device,object}) => {
    const idToken = await authUser.getIdToken(true);
   const url = 'https://app.mechanicalcontrolsystems.com/services/getObject';
   const query = "?" + new URLSearchParams({site,device,object}).toString()
    const headers = {
        'Authorization':`Bearer ${idToken}`,
    };
    const res = await fetch(url+query, {headers});
    return res.json();
}

export const getScheduleData = async ({authUser,site, device,index}) => {
    const idToken = await authUser.getIdToken(true);
   const url = 'https://app.mechanicalcontrolsystems.com/services/getSchedule';
   const query = `?${new URLSearchParams({site,device,index}).toString()}`
    const headers = {
        'Authorization':`Bearer ${idToken}`,
    };
    const res = await fetch(url+query, {headers});
    return res.json();
}


export const updateWeeklyScheduleDay = async ({authUser,site, device,scheduleIndex, day, events}) => {
   const idToken = await authUser.getIdToken(true);
   const data = makeDaySchObj(events)
   const url = 'https://app.mechanicalcontrolsystems.com/services/updateWeeklyScheduleDay';
   const method = 'PUT';
   const body = JSON.stringify({site, device,scheduleIndex, day, data});
    const headers = {
        'Authorization':`Bearer ${idToken}`,
        'Content-Type':'application/json'
    };
    const res = await fetch(url, {headers, method, body});
    return res.text();
}

const GROUP_CACHE = {};
export const getGroups = async ({authUser,client = "default"}) => {
   if(GROUP_CACHE[client]) return GROUP_CACHE[client];
   const idToken = await authUser.getIdToken(true);
   const url = 'https://app.mechanicalcontrolsystems.com/services/fetchGroups';
   const query = client ? `?${new URLSearchParams({client}).toString()}` : "";
    const headers = {
        'Authorization':`Bearer ${idToken}`,
    };
    const res = await fetch(url+query, {headers});
    const result = await res.json();
    console.log(56565, result)
    return GROUP_CACHE[client] = result;
}