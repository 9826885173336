const emailRe =   /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
 

export const isEmail = (txt) => emailRe.test(txt);

export const validE164 = (num) =>  /^\+?[1-9]\d{1,14}$/.test(num);

export const sleep = (ms) => new Promise(res => setTimeout(res,ms));

export const getWeekDays = () => [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
].slice(0);

export const getWeekDay = (day) => getWeekDays()[day];

export const isBetters = group => ["Administrators", "Supervisors"].includes(group);
