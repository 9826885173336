import { createContext, useEffect, useState } from "react";

export const MasterPromptContext = createContext({
  open: false,
  setOpen: () => null,
  setTitle: () => null,
  setContent: () => null,
  handleClose: () => null,
  setHandleClose: () => null,
  title: "",
  content: "",
  buttons: ["Yes", "No"],
  disableEscapeKeyDown: false,
  fullScreen: false,
});

export default function MasterPromptProvider({ children }) {
  const [open, setOpen] = useState(false);
  const [handleClose, setHandleCloseInternal] = useState(() => () => null);
  const [title, setTitle] = useState("")
  const [content, setContent] = useState("")
  const [handleClick, setHandleClickInternal] = useState(() => value => console.log(value))
  const [buttons, setButtons] = useState(["Yes","No"])
  const [disableEscapeKeyDown, setDisableEscapeKeyDown] = useState(false)
  const [fullScreen, setFullScreen] = useState(false);
  const setHandleClose = func => setHandleCloseInternal((reason) => func(reason));
  const setHandleClick = func => setHandleClickInternal(() => value => func(value));
  const prompt = {
    open: ({
      title,
      content,
      buttons = ["Yes","No"],
      disableEscapeKeyDown = false,
      fullScreen = false
    } ={}) => {
      let resolve;
      const clicked = new Promise(res => resolve=res)
      setOpen(true);
      setTitle(title); 
      setContent(content);
      setHandleClick(resolve);
      setButtons(buttons);
      setDisableEscapeKeyDown(disableEscapeKeyDown);
      setFullScreen(fullScreen);
      return clicked;
    },
    close: () => (
      setOpen(false), 
      setTitle(null), 
      setContent(null),
      setHandleClick(value => console.log(value)),
      setButtons(["Yes","No"]),
      setDisableEscapeKeyDown(false),
      setFullScreen(false)
      ),
    title: val => setTitle(val),
    content: val => setContent(val),
    handleClick: val => setHandleClick(val),
    buttons: val => setButtons(val),
    disableEscapeKeyDown: val=> setDisableEscapeKeyDown(val),
    fullScreen: val =>  setFullScreen(val)
  }


  return (
    <MasterPromptContext.Provider
      value={{
        prompt,
        open,
        setOpen,
        handleClose,
        setHandleClose,
        title,
        setTitle,
        content,
        setContent,
        handleClick,
        setHandleClick,
        buttons,
        setButtons,
        disableEscapeKeyDown,
        setDisableEscapeKeyDown,
        fullScreen,
        setFullScreen
      }}
    >
      {children}
    </MasterPromptContext.Provider>
  );
}
