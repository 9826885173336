import { useContext } from 'react';
import { MasterWaitScreenContext } from '../contexts/MasterWaitScreen';
import WaitScreen from './WaitScreen';

export default function MasterWaitScreen() {
  const {
    open,
    handleClose,
    text
  } = useContext(MasterWaitScreenContext);
  return (
      <WaitScreen
      open = {open}
      handleClose = {handleClose}
      text = {text}
      ></WaitScreen>
  );
}
