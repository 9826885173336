import { useContext, useState } from "react";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { DialogActions, DialogContent, Stack } from "@mui/material";
import { isEmail } from "../../utils/Utils";
import {syncUsers, updateUser } from "../../firebase/firestore/Firestore";
import { MasterToastContext } from "../../contexts/MasterToast";
import { MasterWaitScreenContext } from "../../contexts/MasterWaitScreen";

export default function EmailUpdater({
  authUser,
  user,
  open,
  onClose,
}) {
  const {toast} = useContext(MasterToastContext);
  const {waitScreen} = useContext(MasterWaitScreenContext);


  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [emailHelperText, setEmailHelperText] = useState("");

  const [reenterEmail, setReenterEmail] = useState("");
  const [reenterEmailError, setReenterEmailError] = useState(false);
  const [reenterEmailHelperText, setReenterEmailHelperText] = useState("");

  const [submitDisabled, setSubmitDisabled] = useState(true);
  

  const handleEmailChange = ({ target: { value } }) => {
    setEmail(value);
    if(!isEmail(value)){
      setSubmitDisabled(true);
      setEmailHelperText("Invalid Email");
      setEmailError(true)
      return;
    }
    if(value === user.Email){
      setSubmitDisabled(true);
      setEmailHelperText("Current Email");
      setEmailError(true)
      return;
    }
    setEmailHelperText("");
    setEmailError(false);
    if(!reenterEmail) return;
    if(value !== reenterEmail){
      setSubmitDisabled(true);
      setReenterEmailHelperText("No Match");
      setReenterEmailError(true)
      return;
    }
    setReenterEmailHelperText("");
    setReenterEmailError(false);
    setSubmitDisabled(false);
  };

  const handleReenterEmailChange = ({ target: { value } }) => {
    setReenterEmail(value);
    if(!isEmail(email)) return;
    if(value !== email){
      setSubmitDisabled(true);
      setReenterEmailHelperText("No Match");
      setReenterEmailError(true)
      return;
    }
    setReenterEmailHelperText("");
    setReenterEmailError(false)
    setSubmitDisabled(false);
  };

  const handleSubmit = async () => {
    setSubmitDisabled(true);
    waitScreen.open('Updating enteliCLOUD');
    const prop = "Email";
    const value = email;
    try{
     await updateUser({authUser, user, prop, value});
     waitScreen.text('Updating user data')
     await syncUsers({authUser, users:[user]});
     toast.success(`Your email has been changed to ${email}.`)
     onClose();
    }
    catch(f){
      setSubmitDisabled(false);
      toast.error('An error prevented your email from being changed.')
      console.error(f)
    }
    waitScreen.close();
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>Email</DialogTitle>
      <DialogContent>
        <Stack>
          <TextField
            type="email"
            required
            variant="outlined"
            margin="normal"
            id="new-email-input"
            label="Email"
            name="email"
            error = {emailError}
            helperText = {emailHelperText}
            autoFocus
            value={email}
            onChange={handleEmailChange}
          />
          <TextField
            type="email"
            required
            variant="outlined"
            margin="normal"
            id="new-email-retype-input"
            label="Reenter Email"
            name="reenterEmail"
            error = {reenterEmailError}
            helperText = {reenterEmailHelperText}
            value={reenterEmail}
            onChange={handleReenterEmailChange}
          />
        </Stack>

      </DialogContent>
      <DialogActions>
        <Button 
        variant="contained" 
        disabled={submitDisabled}
        onClick = {handleSubmit}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}
