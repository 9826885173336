
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";


import CloseIcon from "@mui/icons-material/Close";
import Profile from "./Profile";


export default function ProfileDialog({
  profileDialogOpen,
  onCloseProfileDialog,
  authUser,
  user,
}) {

  return (
    <Dialog
      open={profileDialogOpen}
      onClose={onCloseProfileDialog}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle sx={{ m: 0, px: 4 }}>
        <IconButton
          aria-label="close"
          onClick={onCloseProfileDialog}
          sx={{
            position: 'absolute',
            right: 4,
            top: 4,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        </DialogTitle>
      <DialogContent dividers>
          <Profile 
          authUser = {authUser}
          user = {user} />
      </DialogContent>
    </Dialog>
  );
}
