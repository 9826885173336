import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

export default function Prompt({
  open,
  handleClose,
  handleClick,
  title,
  content,
  buttons = ["Yes", "No"],
  disableEscapeKeyDown = false,
  fullScreen = false,
}) {
  const renderButton = button => {
    const {
      value,
      text,
      autoFocus = false,
      color = "inherit",
      variant =  "contained",
      size = "small"
    } = typeof button === "string" ?
    {value: button} :
    button;
    return (
      <Button 
      onClick={() => handleClick(value)}
      autoFocus = {autoFocus}
      color = {color}
      variant = {variant}
      size = {size}
      >
        {text || value}
      </Button>
    )
  }
 

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="prompt-dialog-title"
      aria-describedby="prompt-dialog-description"
      disableEscapeKeyDown = {disableEscapeKeyDown}
      fullScreen = {fullScreen}
    >
      {title ? (
      <DialogTitle id="prompt-dialog-title">
        {title}
      </DialogTitle>
      ) : null}
      <DialogContent>
        <DialogContentText id="prompt-dialog-description">
          {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {buttons.map(renderButton)}
      </DialogActions>
    </Dialog>
  );
}
