import {
  Box,
  Divider,
  Fab,
  List,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Stack,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../contexts/Auth";
import { convertWeeklySchedule, formatTimes } from "./Helper";
import { getWeekDay } from "../../utils/Utils";
import ScheduleDialog from "./ScheduleDialog";
import { MasterWaitScreenContext } from "../../contexts/MasterWaitScreen";
import { ObjectsContext } from "../../contexts/Objects";

export default function Scheduler({site, device, object}) {
  const { authUser } = useContext(AuthContext);
  const { waitScreen } = useContext(MasterWaitScreenContext);
  const {getObject} = useContext(ObjectsContext)

  const [title, setTitle] = useState("");


  const [weeklySchedule, setWeeklySchedule] = useState();
  const [eventIndex, setEventIndex] = useState();
  const [currentWeekDay, setCurrentWeekDay] = useState(0);
  const [openScheduleDialog, setOpenScheduleDialog] = useState(false);

  useEffect(() => {
    waitScreen.open('Loading schedule data...')
    if (!authUser) return;
    if(!site || !device || !object) return;
    (async () => {
      //const data = await getScheduleData({authUser, site, device, index});
      const data = await getObject(`${site}/${device}/${object}`);
      setTitle(data["displayName"])
      setWeeklySchedule(convertWeeklySchedule(data["weekly-schedule"]));
      waitScreen.close()
    })();
  }, [authUser, site, device, object]);

  useEffect(() => {
    // FOR TESTING
    console.log(12, weeklySchedule);
  }, [weeklySchedule]);

  const openNewEventDialog = () => {
    setEventIndex(null)
    setCurrentWeekDay([])
    setOpenScheduleDialog(true)
  }
  
  const openEditEventDialog = (currentWeekDay, eventIndex) => {
    setEventIndex(eventIndex)
    setCurrentWeekDay(currentWeekDay)
    setOpenScheduleDialog(true)
  }

  return (
    <Box>
    <Typography variant="h4">{title}</Typography>
    <List sx={{maxWidth:360}}>
      {weeklySchedule?.map((schedule, dayIndex) => (
        <>
          <ListSubheader>{getWeekDay(dayIndex)}</ListSubheader>
          {schedule
            .filter(({ val }) => val === 0)
            .map(({ start, end }, eventIndex) => (
              <ListItemButton onClick={openEditEventDialog.bind(null, dayIndex, eventIndex)}>
                <ListItemText>{formatTimes(start, end)}</ListItemText>
              </ListItemButton>
            ))}
          <Divider />
        </>
      ))}
      <Stack justifyContent="flex-end" direction="row" sx={{p:1}}>
        <Fab color="primary" onClick={openNewEventDialog} >
          <AddIcon />
        </Fab>
      </Stack>
    </List>
    <ScheduleDialog
    {...{
      onCloseScheduleDialog: () => setOpenScheduleDialog(false),
      openScheduleDialog, 
      authUser, 
      site, 
      device, 
      object,
      eventIndex, 
      weeklySchedule, 
      currentWeekDay, 
      setWeeklySchedule
    }}
    />
    </Box>
  );
}
