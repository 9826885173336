import React, { useState, useContext } from "react";
import { AuthContext } from "../../contexts/Auth";
import Profile from "./Profile";




//abstract Profile so it can load other user's profiles

export default function UserProfile() {
  const { user,authUser } = useContext(AuthContext);


  return (
    <Profile 
     authUser = {authUser}
     user = {user}
    />
  );
}
