import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import AlarmList from "./AlarmList";

export default function AlarmSettings({user,authUser}) {
  const [alarmData, setAlarmData] = useState({});

  const [expanded, setExpanded] = useState('panel0');



  useEffect(() => {
    if (!user?.alarms) return setAlarmData({});
    const alarmData = Object.entries(user.alarms).reduce((acc, [key, data]) => {
      if (!acc[data.site]) acc[data.site] = [];
      acc[data.site].push({ ...data, key });
      return acc;
    }, {});
    return setAlarmData(alarmData);
  }, [user]);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div>
      {isEmpty(alarmData) ? (
        <div>Sorry, you do not appear to be assigned any alarms 😞</div>
      ) : (
        Object.entries(alarmData)
          .sort(([a], [b]) => (a > b ? 1 : -1))
          .map(([site, data], i) => (
            <Accordion
              expanded={expanded === `panel${i}`}
              onChange={handleChange(`panel${i}`)}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${i}bh-content`}
                id={`panel${i}bh-header`}
              >
                <Typography>{site}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <AlarmList authUser={authUser} user={user} alarms={data} />
              </AccordionDetails>
            </Accordion>
          ))
      )}
    </div>
  );
}
