import React, { useContext, useState } from "react";
import { syncUsers } from "../../firebase/firestore/Firestore";


import Typography from "@mui/material/Typography";
import EditIcon from '@mui/icons-material/Edit';
import {
  Button,
  Container,
  IconButton,
} from "@mui/material";

import AlarmSettings from "../alarmSettings/AlarmSettings";
import { Box } from "@mui/system";
import EmailUpdater from "./EmailUpdater";
import PhoneNumberUpdater from "./PhoneNumberUpdater";
import PasswordChanger from "./PasswordChanger";
import { MasterPromptContext } from "../../contexts/MasterPrompt";
import { resetPassword } from "../../firebase/functions/Functions";
import { MasterToastContext } from "../../contexts/MasterToast";

export default function Profile({user,authUser}) {

  const {prompt} = useContext(MasterPromptContext);
  const {toast} = useContext(MasterToastContext);

  const [openEmailUpdater, setOpenEmailUpdater] = useState(false);
  const [openPhoneNumberUpdater, setOpenPhoneNumberUpdater] = useState(false);
  const [openPasswordChanger, setOpenPasswordChanger] = useState(false);
 


  const sync = async () => {
    const syncRes = await syncUsers({authUser,users:[user]});
    const jsonRes = await syncRes.json();
     console.log("sync response",jsonRes)
  }

  const confirmResetPassword = async () => {
    const response = await prompt.open({
      title:"Password Reset", 
      content: `Please confirm that you wish to reset the password for ${user.username}`
    });
    if(response !== "Yes") return prompt.close();
    try{
      toast.info(`The password for ${user.username} is being reset...`)
      const res = await resetPassword({authUser,user});      
      console.log(res);
      toast.success(`${user.username} has been emailed a new password.`)
     }
     catch(f){
       toast.error((`An error occured while reseting the password of ${user.username}.`))
       console.error(f)
     }
     prompt.close();
  }  

  return (
    <Container maxWidth="md">
      <Typography variant="h6" component="h3" align="center">
        {`${user?.username} Profile`}
      </Typography>

      <div style={{ display: "flex", justifyContent: "space-between", margin: 10 }}>
      <Button variant="outlined" size="small" onClick={sync}>
          Refresh
        </Button>
       { user?.uid === authUser?.uid ? (
        <Button variant="outlined" size="small" 
        onClick={() => setOpenPasswordChanger(true)}
        >
          Change Password
        </Button>
        ) : (
        <Button variant="outlined" size="small" 
        onClick={confirmResetPassword}
        >
          Reset Password
        </Button>
        )}
        {/*
        <Button variant="outlined" size="small" onClick={signOutUser}>
          Sign Out
        </Button>
  */}
      </div>
      <Box>
      <Typography variant="body1"><span style={{fontWeight: 'bold'}}>Email: </span>{user?.email || ""}   
      <IconButton 
      aria-label="edit" 
      onClick={() => setOpenEmailUpdater(true)} >
        <EditIcon />
      </IconButton>
      </Typography>  
      <EmailUpdater 
      authUser = {authUser}
      user= {user}
      open={openEmailUpdater} 
      onClose={() => setOpenEmailUpdater(false)}/>
      <Typography variant="body1"><span style={{fontWeight: 'bold'}}>Phone Number: </span>{user?.phoneNumber || ""}   
      <IconButton 
      aria-label="edit" 
      onClick={() => setOpenPhoneNumberUpdater(true)} >
        <EditIcon />
      </IconButton>
      </Typography>  
      <PhoneNumberUpdater 
      authUser = {authUser}
      user= {user} 
      open={openPhoneNumberUpdater} 
      onClose={() => setOpenPhoneNumberUpdater(false)}
      />
      <PasswordChanger
      authUser = {authUser}
      open = {openPasswordChanger}
      onClose = {() => setOpenPasswordChanger(false)}
      />
      </Box>
        <AlarmSettings      
         authUser = {authUser}
         user= {user} />
    </Container>
  );
}
