import { useContext, useState } from "react";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import { DialogActions, DialogContent, Stack } from "@mui/material";
import { validE164 } from "../../utils/Utils";
import {syncUsers, updateUser } from "../../firebase/firestore/Firestore";
import { MasterToastContext } from "../../contexts/MasterToast";
import MuiPhoneInput from "material-ui-phone-number";
import { MasterWaitScreenContext } from "../../contexts/MasterWaitScreen";

export default function PhoneNumberUpdater({
  authUser,
  user,
  open,
  onClose,
}) {
  const {toast} = useContext(MasterToastContext);
  const {waitScreen} = useContext(MasterWaitScreenContext);

  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState(false);
  const [phoneNumberHelperText, setPhoneNumberHelperText] = useState("");

  const [reenterPhoneNumber, setReenterPhoneNumber] = useState("");
  const [reenterPhoneNumberError, setReenterPhoneNumberError] = useState(false);
  const [reenterPhoneNumberHelperText, setReenterPhoneNumberHelperText] = useState("");

  const [submitDisabled, setSubmitDisabled] = useState(true);
  

  const handlePhoneNumberChange = (value) => {
    setPhoneNumber(value);
    if(!validE164(value)){
      setSubmitDisabled(true);
      setPhoneNumberHelperText("Invalid Phone Number");
      setPhoneNumberError(true)
      return;
    }
    if(value === phoneNumber){
      setSubmitDisabled(true);
      setPhoneNumberHelperText("Current Phone Number");
      setPhoneNumberError(true)
      return;
    }
    setPhoneNumberHelperText("");
    setPhoneNumberError(false);
    if(!reenterPhoneNumber) return;
    if(value !== reenterPhoneNumber){
      setSubmitDisabled(true);
      setReenterPhoneNumberHelperText("No Match");
      setReenterPhoneNumberError(true)
      return;
    }
    setReenterPhoneNumberHelperText("");
    setReenterPhoneNumberError(false);
    setSubmitDisabled(false);
  };

  const handleReenterPhoneNumberChange = (value) => {
    setReenterPhoneNumber(value);
    if(!validE164(value)) return;
    if(value !== phoneNumber){
      setSubmitDisabled(true);
      setReenterPhoneNumberHelperText("No Match");
      setReenterPhoneNumberError(true)
      return;
    }
    setReenterPhoneNumberHelperText("");
    setReenterPhoneNumberError(false)
    setSubmitDisabled(false);
  };

  const handleSubmit = async () => {
    setSubmitDisabled(true);
    waitScreen.open('Updating enteliCLOUD');
    try{
     const prop =  "PhoneNumber";
     const value = phoneNumber.slice(1);
     await updateUser({authUser, user, prop, value});
     waitScreen.text('Updating user data');
     await syncUsers({authUser,users:[user]});

     toast.success(`Your phone number has been changed to ${value}.`);
     onClose();
    }
    catch(f){
      setSubmitDisabled(false);
      toast.error('An error prevented your phone number from being changed.')
      console.error(f)
    }
    waitScreen.close();
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>Phone Number</DialogTitle>
      <DialogContent>
        <Stack>
        <MuiPhoneInput
        id="new-phone-number-input"
        defaultCountry={"us"}
        autoFormat={false}
        variant="outlined"
        margin="normal"
        autoFocus
        error = {phoneNumberError}
        helperText = {phoneNumberHelperText}
        value={phoneNumber}
        onChange={handlePhoneNumberChange}
      />
      <MuiPhoneInput
        id="new-phone-number-reenter-input"
        defaultCountry={"us"}
        autoFormat={false}
        variant="outlined"
        margin="normal"
        error = {reenterPhoneNumberError}
        helperText = {reenterPhoneNumberHelperText}
        value={reenterPhoneNumber}
        onChange={handleReenterPhoneNumberChange}
      />
        
        </Stack>

      </DialogContent>
      <DialogActions>
        <Button 
        variant="contained" 
        disabled={submitDisabled}
        onClick = {handleSubmit}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}
