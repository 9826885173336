import { useContext } from 'react';
import { MasterPromptContext } from '../contexts/MasterPrompt';
import Prompt from './Prompt';

export default function MasterPrompt() {
  const {
    open,
    handleClose,
    handleClick,
    title,
    content,
    buttons,
    disableEscapeKeyDown,
    fullScreen,
  } = useContext(MasterPromptContext);

  return (
      <Prompt
      open = {open}
      handleClose = {handleClose}
      handleClick = {handleClick}
      title = {title}
      content = {content}
      buttons = {buttons}
      disableEscapeKeyDown = {disableEscapeKeyDown}
      fullScreen = {fullScreen}
      ></Prompt>
  );
}
