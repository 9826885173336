/* TODO
- make users context 
-- check authUser and if supervisor or admin continue
-- make backend syncUsers that takes list of users to sync async
- make list ui of users with subtitles for supervisors and sites
-- when user is clicked on goes to modal
-- modal allows user data to be updated and alarm comms changed
- make add user modal
-- make add button in corner of app
-- when user created create temp rand password and email user
-- create reset password option that resets password and emails temp password
- write db security rules


*/

import { createContext, useState, useContext, useEffect } from "react";
import { AuthContext } from "./Auth";
import { collection, query, where, onSnapshot } from "firebase/firestore";
import { db, syncUsers } from "../firebase/firestore/Firestore";
import { isEmpty } from "lodash";

export const UsersContext = createContext();

export default function UsersProvider({ children }) {
  const { authUser, user } = useContext(AuthContext);
  const [users, setUsers] = useState({});
  const [synced, setSynced] = useState();

  useEffect(() => {
    if (!user) return setUsers({});
    const betters = ["Administrators", "Supervisors"];
    if (!betters.includes(user.group)) return;
    const q = query(collection(db, "users"), 
        user.group === "Administrators" 
        ? where("group", "!=", "Administrators")
        : where("group", "not-in", betters), 
        where('client', "==", user.client));
    return onSnapshot(q, (qSnap) =>
      setUsers(
        qSnap.docs.reduce((acc,doc) => ({ ...acc, [doc.id]: doc.data() }), {})
      )
    );
  }, [user]);

  useEffect(() =>{
    const func = async () => {
      if(!authUser || isEmpty(users || {})) return;
      //if(synced && synced.every(({reason}) => !reason)) return;
      if(synced) return;
      const userArr = Object.entries(users)
      .map(([uid,{client,username,group}]) => ({uid,client,username,group}))
      const res = await syncUsers({ authUser, users: userArr })
      const result = await res.json();
      setSynced(result)
      console.log(result)
    }
    func()
  }, [authUser, users, synced])

  useEffect(() => console.log("USERS", users), [users])

  return (
    <UsersContext.Provider value={{ users }}>
      {children}
    </UsersContext.Provider>
  );
}
