import { Divider, IconButton, List, ListItem, ListItemText, ListSubheader, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { isEmpty } from "lodash";
import { useContext, useEffect, useState } from "react";
import { MasterWaitScreenContext } from "../../contexts/MasterWaitScreen";
import { UsersContext } from "../../contexts/Users";
import { AuthContext } from "../../contexts/Auth";
import ProfileDialog from "../profile/ProfileDialog";
import UserCreatorButton from "../userCreator/UserCreatorButton";
import PersonIcon from '@mui/icons-material/Person';
import UserListItem from "./UserListItem";
import { getGroups } from "../../firebase/functions/Functions";

export default function Users() {
 const {authUser, user: authUserData} = useContext(AuthContext)
 const {users} = useContext(UsersContext);
 const { waitScreen } = useContext(MasterWaitScreenContext);
 const [profileDialogOpen, setProfileDialogOpen] = useState(false);
 const [userData, setUserData] = useState([])
 const [user, setUser] = useState({})
 


 const openProfileDialog = (user) => {
   if(!user || isEmpty(user)) return;
   setUser(user);
   setProfileDialogOpen(true);
 };



 useEffect(() =>{
    waitScreen.open('Loading users');
    if(!users) return;
    const userData = Object.entries(users)
    .map(([uid,user]) => ({...user,uid}))
    .sort(({username: a}, {username: b}) => a > b ? 1 : -1)
     setUserData(userData)
     waitScreen.close();
 },[users]);

 useEffect(() =>{
  if(!users) return;
  if(isEmpty(user)) return;
  if(!users[user.uid]) return;
  setUser({uid: user.uid,...users[user.uid]})
},[users]);

useEffect(() =>{
  if(!authUserData) return;
  if(isEmpty(user)) return;
  if(user.uid !== authUserData.uid) return;
  setUser(authUserData)

},[authUserData]);

useEffect(() =>{
  getGroups({authUser})
},[])

return (
    <Box>
    <Stack 
    direction="row"
    justifyContent="flex-end"
    >
    <IconButton
    onClick={() => openProfileDialog(authUserData)}
    >
    <PersonIcon />

    </IconButton>
    </Stack>
    <Typography variant="h4">Users</Typography>
    <List sx={{maxWidth:360}}>
    {userData?.map(user => (
    <UserListItem {...{authUser,user,openProfileDialog}} />
    ))}
    </List>
    <UserCreatorButton 
    authUser = {authUser}
    user = {authUserData}
    />
     <ProfileDialog
             {...{
              onCloseProfileDialog: () => setProfileDialogOpen(false),
              profileDialogOpen,
              authUser,
              user
            }}
      />          
    </Box>
)

}
